import { Time } from "@/models/models";

export default class TimeService {
  private static lsName = "times";

  public static getAll(): Time[] {
    return JSON.parse(localStorage.getItem(this.lsName) || "[]");
  }

  public static add(time: Time): Time[] {
    const times = this.getAll();
    const updatedTimes = [time, ...times];
    localStorage.setItem(this.lsName, JSON.stringify(updatedTimes));
    return updatedTimes;
  }

  public static deleteOne(index: number): Time[] {
    const times = this.getAll();
    const updatedTimes = times.filter((item) => item.id !== index);
    localStorage.setItem(this.lsName, JSON.stringify(updatedTimes));
    return updatedTimes;
  }

  public static deleteAll(): Time[] {
    localStorage.removeItem(this.lsName);
    return [];
  }
}
