<template>
  <div :class="$style.HomePage">
    <HomePageList :timeList="timeList" @onLongPress="onLongPress" />

    <button :class="$style.button" @click="onClickButton">Добавить</button>

    <button :class="$style.deleteAll" @click="onClickDeleteAll">x</button>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from "vue";

import TimeService from "@/services/TimeService";
import { Time } from "@/models/models";

import HomePageList from "@/components/pages/home-page/HomePageList.vue";

const timeList = ref<Time[]>([]);

onMounted(() => {
  timeList.value = TimeService.getAll();
});

function onClickButton() {
  const h = new Date().getHours();
  const m = new Date().getMinutes();

  const transformedValue = (payload: number) => {
    return payload > 9 ? payload : `0${payload}`;
  };

  timeList.value = TimeService.add(
    new Time(Date.now(), `${transformedValue(h)}:${transformedValue(m)}`)
  );
}

function onLongPress(id: number) {
  if (confirm("Удалить?")) {
    timeList.value = TimeService.deleteOne(id);
  }
}

function onClickDeleteAll() {
  if (confirm("Удалить все?")) {
    timeList.value = TimeService.deleteAll();
  }
}
</script>

<style lang="scss" module>
.HomePage {
  position: relative;
  padding-bottom: 80px;
}

.button {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background-color: #40e9ae;
  border: none;
  font-size: 24px;
  transition: background-color 0.3s ease;

  &:active {
    background-color: darken(#40e9ae, 10);
  }
}

.deleteAll {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #000;
  border-radius: 100%;
  width: 20px;
  height: 20px;
}
</style>
