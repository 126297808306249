<template>
  <li :class="$style.HomePageListItem">
    <OnLongPress as="span" @trigger="onLongPress">
      <span :class="$style.inner">
        {{ data.time }}
      </span>
    </OnLongPress>
  </li>
</template>

<script lang="ts" setup>
import { Time } from "@/models/models";

import { OnLongPress } from "@vueuse/components";

const props = defineProps<{
  data: Time;
}>();

const emit = defineEmits();

function onLongPress() {
  emit("onLongPress", props.data.id);
}
</script>

<style lang="scss" module>
.HomePageListItem {
  display: block;
  border-radius: 8px;
  overflow: hidden;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.inner {
  display: block;
  padding: 10px;
  background-color: #cecece;
}
</style>
